.owl-carousel .owl-nav.disabled {
    display: block;
}

.owl-carousel
{
    > .owl-nav
    {
        width: 100%;
        height: v(237);
        position: absolute;
        top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        pointer-events: none;
      
    }
}


.owl-carousel .owl-nav button.owl-prev {
    color: transparent;
    pointer-events: visible;

    position: absolute;
   
    left: -4.7vmin;

    width: v(38);
    height: v(38);

    background-image: url("../img/arrow.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    transition: background-image 0.25s ease;

    &:hover {
        background-image: url("../img/arrow-hover.svg");
        transition: background-image 0.25s ease;
    }
}

.owl-carousel .owl-nav button.owl-next {
    color: transparent;
    pointer-events: visible;
    
    position: absolute;

    right: -4.7vmin;

    width: v(38);
    height: v(38);

    background-image: url("../img/arrow-next.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    transition: background-image 0.25s ease;

    &:hover {
        background-image: url("../img/arrow-next-hover.svg");
        transition: background-image 0.25s ease;
    }
}

.owl-carousel.owl-drag .owl-item 
{
    display: flex;
    justify-content: center;
}

.mfp-counter
{
    display: none;
}