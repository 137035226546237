html {
    box-sizing: border-box;

    scroll-behavior: smooth;
}

*,
*::before, *::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;

    font-family: "Intro", sans-serif;
    font-weight: 400;
    font-size: v(16);

    color: $maintext;
}

.modal-active {
    overflow: hidden;
}

img {
    max-width: 100%;
}

a {
    text-decoration-line: none;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

li {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

button {
    cursor: pointer;
}

.visually-hidden {
	position: absolute !important;
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
	padding:0 !important;
	border:0 !important;
	height: 1px !important; 
	width: 1px !important; 
	overflow: hidden;
}