@import url(../addon/owl.carousel.min.css);
@import url(../addon/owl.theme.default.min.css);
@import url(../addon/magnific-popup.css);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

@font-face {
  font-weight: 300;
  font-style: normal;
  font-family: "Intro";
  font-display: swap;
  src: local("Intro Book"), url("../fonts/Intro-Book.woff") format("woff"); }

@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: "Intro";
  font-display: swap;
  src: local("Intro Regular"), url("../fonts/Intro-Regular.woff") format("woff"); }

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: "Intro";
  font-display: swap;
  src: local("Intro Bold"), url("../fonts/Intro-Bold.woff") format("woff"); }

@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: "Roboto";
  font-display: swap;
  src: local("Roboto Regular"), url("../fonts/Roboto-Regular.woff") format("woff"); }

html {
  box-sizing: border-box;
  scroll-behavior: smooth; }

*,
*::before, *::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  font-family: "Intro", sans-serif;
  font-weight: 400;
  font-size: 1.6vmin;
  color: #211621; }

.modal-active {
  overflow: hidden; }

img {
  max-width: 100%; }

a {
  text-decoration-line: none; }

ul {
  list-style-type: none;
  padding: 0;
  margin: 0; }

li {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

button {
  cursor: pointer; }

.visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden; }

.container {
  max-width: 100%;
  margin: 0 0.52632vw;
  padding: 0 0.78947vw; }

.owl-carousel .owl-nav.disabled {
  display: block; }

.owl-carousel > .owl-nav {
  width: 100%;
  height: 23.7vmin;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none; }

.owl-carousel .owl-nav button.owl-prev {
  color: transparent;
  pointer-events: visible;
  position: absolute;
  left: -4.7vmin;
  width: 3.8vmin;
  height: 3.8vmin;
  background-image: url("../img/arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: background-image 0.25s ease; }
  .owl-carousel .owl-nav button.owl-prev:hover {
    background-image: url("../img/arrow-hover.svg");
    transition: background-image 0.25s ease; }

.owl-carousel .owl-nav button.owl-next {
  color: transparent;
  pointer-events: visible;
  position: absolute;
  right: -4.7vmin;
  width: 3.8vmin;
  height: 3.8vmin;
  background-image: url("../img/arrow-next.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: background-image 0.25s ease; }
  .owl-carousel .owl-nav button.owl-next:hover {
    background-image: url("../img/arrow-next-hover.svg");
    transition: background-image 0.25s ease; }

.owl-carousel.owl-drag .owl-item {
  display: flex;
  justify-content: center; }

.mfp-counter {
  display: none; }

.page.main-active {
  overflow: hidden; }

.page .header {
  width: 100%;
  height: auto;
  padding: 5.05vmin 0 0;
  display: flex;
  justify-content: center; }
  .page .header > .container {
    width: 190vmin;
    padding: 0 3.44737vw 0 3.42632vw;
    display: flex;
    justify-content: center; }
    .page .header > .container > .contact-list {
      width: 100%;
      display: flex;
      justify-content: center; }
      .page .header > .container > .contact-list > .item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 1.05263vw; }
        .page .header > .container > .contact-list > .item:nth-child(1) {
          background-image: url("../img/mail.svg");
          background-repeat: no-repeat;
          background-size: 1.8vmin 1.4vmin;
          background-position: left;
          padding-left: 1.05263vw;
          transition: background-image 0.25s ease; }
          .page .header > .container > .contact-list > .item:nth-child(1):hover {
            background-image: url("../img/mail-blue.svg");
            transition: background-image 0.25s ease; }
        .page .header > .container > .contact-list > .item:nth-child(2) {
          background-image: url("../img/phone.svg");
          background-repeat: no-repeat;
          background-size: 1.2vmin 1.7vmin;
          background-position: left;
          padding-left: 0.78947vw;
          transition: background-image 0.25s ease; }
          .page .header > .container > .contact-list > .item:nth-child(2):hover {
            background-image: url("../img/phone-blue.svg");
            transition: background-image 0.25s ease; }
        .page .header > .container > .contact-list > .item:nth-child(3) {
          background-image: url("../img/call.svg");
          background-repeat: no-repeat;
          background-size: 1.8vmin 1.4vmin;
          background-position: left;
          padding-left: 1.31579vw;
          transition: background-image 0.25s ease; }
          .page .header > .container > .contact-list > .item:nth-child(3):hover {
            background-image: url("../img/call-blue.svg");
            transition: background-image 0.25s ease; }
        .page .header > .container > .contact-list > .item > .link {
          color: #211621;
          font-weight: 300;
          font-size: 1.6vmin;
          line-height: 2.4vmin;
          display: inline-block;
          border-bottom: 0.1vmin solid #CBCBCB;
          transition: color 0.25s ease; }
          .page .header > .container > .contact-list > .item > .link:hover {
            color: #6E73FF;
            border-bottom: 0.1vmin solid #6E73FF;
            transition: all 0.25s ease; }
        .page .header > .container > .contact-list > .item > .call-btn {
          color: #211621;
          border: none;
          border-bottom: 0.1vmin solid #CBCBCB;
          background-color: transparent;
          font-weight: 300;
          font-size: 1.6vmin;
          line-height: 2.4vmin;
          transition: color 0.25s ease; }
          .page .header > .container > .contact-list > .item > .call-btn:hover {
            color: #6E73FF;
            border-bottom: 0.1vmin solid #6E73FF;
            transition: all 0.25s ease; }

.page .main {
  overflow: hidden; }
  .page .main > .home {
    width: 100%;
    height: auto;
    margin-top: 21.5vmin;
    margin-bottom: 23.9vmin;
    display: flex;
    flex-direction: column; }
    .page .main > .home > .container {
      display: flex;
      flex-direction: column; }
      .page .main > .home > .container > .title {
        font-size: 2.4vmin;
        line-height: 3.6vmin;
        text-align: center; }
      .page .main > .home > .container > .logo {
        width: 45.37vmin;
        height: 19.063vmin;
        margin: 2.8vmin auto 6.037vmin; }
      .page .main > .home > .container > .call-btn {
        background: #787DFF;
        border-radius: 0.4vmin;
        border: none;
        width: 24.1vmin;
        height: 4.4vmin;
        padding: 1vmin 5vmin;
        margin: 0 auto;
        color: #ffffff;
        font-family: "Roboto";
        font-weight: 600;
        font-size: 1.6vmin;
        transition: transform 0.3s ease; }
        .page .main > .home > .container > .call-btn:hover {
          transform: perspective(40vmin) rotateX(30deg);
          background: #878CFF;
          transition: all 0.3s ease; }
        .page .main > .home > .container > .call-btn:active {
          background: #6E73FF; }
  .page .main > .event {
    position: relative;
    width: 100%;
    height: auto;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 9vmin 0;
    margin-bottom: 16.5vmin;
    display: flex;
    justify-content: center; }
    .page .main > .event > .container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .page .main > .event > .container > .title {
        color: #ffffff;
        font-weight: 700;
        font-size: 4.8vmin;
        line-height: 4.8vmin;
        margin-bottom: 3.2vmin; }
      .page .main > .event > .container > .event-list {
        display: flex;
        flex-direction: column; }
        .page .main > .event > .container > .event-list > .item {
          margin-top: 2.3vmin;
          padding: 0 5vmin;
          text-align: center;
          transition: background 0.3s ease;
          cursor: pointer; }
          .page .main > .event > .container > .event-list > .item:hover {
            background: url("../img/Line1.svg") no-repeat, url("../img/Line2.svg") no-repeat;
            background-size: 3.8vmin 0.1vmin;
            background-position: left 35%, right 35%;
            transition: background 0.3s ease; }
            .page .main > .event > .container > .event-list > .item:hover > .image {
              opacity: 1;
              transition: opacity 0.3s ease; }
          .page .main > .event > .container > .event-list > .item > .descr {
            color: #ffffff;
            font-weight: 400;
            font-size: 2.4vmin;
            line-height: 150%; }
          .page .main > .event > .container > .event-list > .item > .image {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0;
            z-index: -1;
            transition: opacity 0.3s ease; }
            .page .main > .event > .container > .event-list > .item > .image_1 {
              opacity: 1;
              transition: opacity 0.3s ease; }
  .page .main > .projects {
    width: 100%;
    height: auto;
    padding-bottom: 14.2vmin;
    margin-bottom: 5.2vmin;
    border-bottom: 0.1vmin solid #E3E1EF;
    display: flex;
    justify-content: center; }
    .page .main > .projects > .container {
      width: 120vmin;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 6vw; }
      .page .main > .projects > .container > .title {
        font-weight: 700;
        font-size: 4.8vmin;
        line-height: 4.8vmin;
        text-align: center;
        background-image: url("../img/Rectangle-project.svg");
        background-repeat: no-repeat;
        background-size: 20.7vmin 5.6vmin;
        background-position: left center; }
      .page .main > .projects > .container > .projects-list {
        width: 120vmin;
        height: auto;
        max-width: 100%;
        display: flex;
        margin-top: 5.7vmin; }
        .page .main > .projects > .container > .projects-list > div > div > div > .item {
          width: 34.6vmin;
          margin: 0 0.8vmin; }
          .page .main > .projects > .container > .projects-list > div > div > div > .item > .link {
            text-decoration-style: none; }
            .page .main > .projects > .container > .projects-list > div > div > div > .item > .link > article {
              width: 100%;
              height: auto;
              display: flex;
              flex-direction: column-reverse; }
              .page .main > .projects > .container > .projects-list > div > div > div > .item > .link > article:hover .descr {
                color: #6E73FF;
                border-bottom: 0.1vmin solid #6E73FF;
                transition: all 0.25s ease; }
              .page .main > .projects > .container > .projects-list > div > div > div > .item > .link > article > .descr {
                color: #211621;
                margin: 2vmin auto 0;
                font-size: 2.4vmin;
                line-height: 150%;
                text-align: center;
                border-bottom: 0.1vmin solid #CBCBCB;
                transition: color 0.25s ease; }
              .page .main > .projects > .container > .projects-list > div > div > div > .item > .link > article > .image {
                width: 100%;
                height: 23.7vmin;
                object-fit: cover; }
  .page .main > .team {
    width: 100%;
    height: auto;
    margin-bottom: 12vmin;
    display: flex;
    justify-content: center; }
    .page .main > .team > .container {
      width: 120vmin;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .page .main > .team > .container > .title {
        font-weight: 700;
        font-size: 4.8vmin;
        line-height: 4.8vmin;
        text-align: center;
        background-image: url("../img/rectangle-team.svg");
        background-repeat: no-repeat;
        background-size: 15.9vmin 5.6vmin;
        background-position: left center; }
      .page .main > .team > .container > .subtitle {
        width: 51.2vmin;
        margin: 2vmin 0 8.4vmin;
        font-weight: 300;
        font-size: 1.6vmin;
        line-height: 150%;
        text-align: center; }
      .page .main > .team > .container > .main-block {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between; }
        .page .main > .team > .container > .main-block > .team-list {
          width: 50%;
          height: auto;
          display: flex;
          flex-direction: column; }
          .page .main > .team > .container > .main-block > .team-list_right {
            padding-top: 21.6vmin; }
          .page .main > .team > .container > .main-block > .team-list > .item {
            width: 100%;
            height: 37.9vmin;
            margin-bottom: 6vmin; }
            .page .main > .team > .container > .main-block > .team-list > .item > article {
              position: relative;
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-end; }
              .page .main > .team > .container > .main-block > .team-list > .item > article:hover .name {
                background-color: #FFCA53;
                transition: all 0.3s ease; }
              .page .main > .team > .container > .main-block > .team-list > .item > article:hover .image {
                transform: rotate(-2deg);
                transition: all 0.3s ease; }
                .page .main > .team > .container > .main-block > .team-list > .item > article:hover .image_right {
                  transform: rotate(2deg); }
              .page .main > .team > .container > .main-block > .team-list > .item > article > .name {
                width: 100%;
                font-weight: 300;
                font-size: 4.8vmin;
                line-height: 4.8vmin;
                text-align: right;
                padding: 1.2vmin 3vw 1.2vmin 3vw;
                margin-bottom: 1.6vmin;
                border: 0.1vmin solid #FFCA53;
                transition: background-color 0.3s ease; }
                .page .main > .team > .container > .main-block > .team-list > .item > article > .name_right {
                  padding: 1.2vmin 3vw 1.2vmin 3vw;
                  text-align: left; }
              .page .main > .team > .container > .main-block > .team-list > .item > article > .descr {
                font-weight: 300;
                font-size: 1.6vmin;
                line-height: 150%;
                width: 18vmin; }
                .page .main > .team > .container > .main-block > .team-list > .item > article > .descr_right {
                  width: 20vmin;
                  margin: 0 35vmin 0 auto; }
              .page .main > .team > .container > .main-block > .team-list > .item > article > .image {
                position: absolute;
                top: 1.6vmin;
                left: 1.6vmin;
                width: 30vmin;
                height: 36.5vmin;
                object-fit: cover;
                z-index: 3;
                transition: transform 0.3s ease; }
                .page .main > .team > .container > .main-block > .team-list > .item > article > .image_right {
                  left: auto;
                  right: 1.6vmin; }
  .page .main > .contact {
    width: 100%;
    height: auto;
    padding-bottom: 10.7vmin;
    margin-bottom: 3vmin;
    border-bottom: 0.1vmin solid #E3E1EF;
    display: flex;
    justify-content: center; }
    .page .main > .contact > .container {
      width: 54vmin;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .page .main > .contact > .container > .title {
        font-weight: 700;
        font-size: 4.8vmin;
        line-height: 4.8vmin;
        text-align: center;
        background-image: url("../img/Rectangle-contact.svg");
        background-repeat: no-repeat;
        background-size: 31.9vmin 5.6vmin;
        background-position: left center; }
      .page .main > .contact > .container > .subtitle {
        font-weight: 300;
        font-size: 1.6vmin;
        line-height: 150%;
        margin: 2vmin 0 2.9vmin; }
      .page .main > .contact > .container > .block-btn {
        width: 100%;
        display: flex;
        justify-content: space-between; }
        .page .main > .contact > .container > .block-btn > .btn {
          width: 24.1vmin;
          height: 4.4vmin;
          padding: 1vmin 5vmin;
          background: #787DFF;
          border: none;
          border-radius: 0.4vmin;
          font-family: "Roboto";
          font-weight: 600;
          font-size: 1.6vmin;
          line-height: 150%;
          text-align: center;
          color: #ffffff;
          transition: transform 0.3s ease; }
          .page .main > .contact > .container > .block-btn > .btn:hover {
            transform: perspective(40vmin) rotateX(30deg);
            background: #878CFF;
            transition: all 0.3s ease; }
          .page .main > .contact > .container > .block-btn > .btn:active {
            background: #6E73FF; }
        .page .main > .contact > .container > .block-btn > .link {
          width: 24.1vmin;
          height: 4.4vmin;
          padding: 1vmin 5vmin;
          background: #787DFF;
          border: none;
          border-radius: 0.4vmin;
          font-family: "Roboto";
          font-weight: 600;
          font-size: 1.6vmin;
          line-height: 150%;
          text-align: center;
          color: #ffffff;
          transition: transform 0.3s ease; }
          .page .main > .contact > .container > .block-btn > .link:hover {
            transform: perspective(40vmin) rotateX(30deg);
            background: #878CFF;
            transition: all 0.3s ease; }
          .page .main > .contact > .container > .block-btn > .link:active {
            background: #6E73FF; }

.page .footer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-bottom: 3vmin; }
  .page .footer > .container {
    width: 60vmin;
    display: flex;
    justify-content: space-between; }
    .page .footer > .container > .left {
      display: flex;
      flex-direction: column; }
      .page .footer > .container > .left > .top-left {
        display: flex; }
        .page .footer > .container > .left > .top-left > .logo {
          width: 9.7vmin;
          height: 4.076vmin;
          margin-right: 0.63158vw; }
        .page .footer > .container > .left > .top-left > .logo-descr {
          font-weight: 700;
          font-size: 2.4vmin;
          line-height: 150%; }
      .page .footer > .container > .left > .address {
        margin-top: 2.2vmin; }
        .page .footer > .container > .left > .address > .address-list {
          display: flex;
          flex-direction: column;
          justify-content: center; }
          .page .footer > .container > .left > .address > .address-list > .item:nth-child(1) {
            background-image: url("../img/phone.svg");
            background-repeat: no-repeat;
            background-size: 1.2vmin 1.7vmin;
            background-position: left 75%;
            padding-left: 0.84211vw;
            transition: background-image 0.25s ease; }
            .page .footer > .container > .left > .address > .address-list > .item:nth-child(1):hover {
              background-image: url("../img/phone-blue.svg");
              transition: background-image 0.25s ease; }
          .page .footer > .container > .left > .address > .address-list > .item:nth-child(2) {
            background-image: url("../img/call.svg");
            background-repeat: no-repeat;
            background-size: 1.8vmin 1.4vmin;
            background-position: left 75%;
            padding-left: 1.05263vw;
            transition: background-image 0.25s ease; }
            .page .footer > .container > .left > .address > .address-list > .item:nth-child(2):hover {
              background-image: url("../img/call-blue.svg");
              transition: background-image 0.25s ease; }
          .page .footer > .container > .left > .address > .address-list > .item:nth-child(3) {
            background-image: url("../img/mail.svg");
            background-repeat: no-repeat;
            background-size: 1.8vmin 1.4vmin;
            background-position: left 75%;
            padding-left: 1.05263vw;
            transition: background-image 0.25s ease; }
            .page .footer > .container > .left > .address > .address-list > .item:nth-child(3):hover {
              background-image: url("../img/mail-blue.svg");
              transition: background-image 0.25s ease; }
          .page .footer > .container > .left > .address > .address-list > .item > .link {
            font-weight: 300;
            font-size: 1.6vmin;
            line-height: 150%;
            color: #211621;
            display: inline-block;
            margin: 1.2vmin auto 0;
            border-bottom: 0.1vmin solid #CBCBCB;
            transition: color 0.25s ease; }
            .page .footer > .container > .left > .address > .address-list > .item > .link:hover {
              color: #6E73FF;
              border-bottom: 0.1vmin solid #6E73FF;
              transition: all 0.25s ease; }
          .page .footer > .container > .left > .address > .address-list > .item > .btn-call {
            font-weight: 300;
            font-size: 1.6vmin;
            line-height: 150%;
            padding: 0;
            color: #211621;
            border: none;
            background-color: transparent;
            margin: 1.2vmin auto 0;
            border-bottom: 0.1vmin solid #CBCBCB;
            transition: color 0.25s ease; }
            .page .footer > .container > .left > .address > .address-list > .item > .btn-call:hover {
              color: #6E73FF;
              border-bottom: 0.1vmin solid #6E73FF;
              transition: all 0.25s ease; }
    .page .footer > .container > .right {
      display: flex;
      flex-direction: column; }
      .page .footer > .container > .right > .descr {
        font-weight: 300;
        font-size: 1.6vmin;
        line-height: 150%;
        margin-bottom: 0.2vmin; }
      .page .footer > .container > .right > .messanger-list {
        display: flex;
        align-items: center;
        margin-bottom: 2.1vmin; }
        .page .footer > .container > .right > .messanger-list > .item > .link {
          display: inline-block;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center; }
          .page .footer > .container > .right > .messanger-list > .item > .link_whatsapp {
            width: 3.3vmin;
            height: 3.4vmin;
            background-image: url("../img/watsap.svg"); }
          .page .footer > .container > .right > .messanger-list > .item > .link_telegram {
            width: 2.4vmin;
            height: 2.4vmin;
            background-image: url("../img/telegram.svg");
            margin: 0 1.6vmin 0 1.4vmin; }
          .page .footer > .container > .right > .messanger-list > .item > .link_viber {
            width: 2.2vmin;
            height: 2.4vmin;
            background-image: url("../img/viber.svg"); }
      .page .footer > .container > .right > .social-list {
        display: flex;
        align-items: center;
        margin-bottom: 2.4vmin; }
        .page .footer > .container > .right > .social-list > .item > .link {
          display: inline-block;
          width: 2.4vmin;
          height: 2.4vmin;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center; }
          .page .footer > .container > .right > .social-list > .item > .link_instagram {
            background-image: url("../img/instagram.svg");
            margin-right: 1.5vmin; }
          .page .footer > .container > .right > .social-list > .item > .link_facebook {
            background-image: url("../img/fb.svg"); }
      .page .footer > .container > .right > .rights {
        font-weight: 300;
        font-size: 1.6vmin;
        line-height: 150%; }

.page .modal {
  position: fixed;
  top: -100%;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: top 0.3s ease; }
  .page .modal.modal-active {
    top: 0;
    transition: top 0.3s ease; }
    .page .modal.modal-active .click-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5; }
    .page .modal.modal-active .call {
      width: 60vmin;
      max-width: 100%;
      height: 40vmin;
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 0.7vmin 0.36842vw;
      background-color: #FFCA53;
      border-radius: 0.8vmin;
      z-index: 15;
      box-shadow: 0.6vmin 0.6vmin 1.5vmin -0.1vmin rgba(0, 0, 0, 0.25);
      transition: top 0.25s ease; }
      .page .modal.modal-active .call.hide {
        display: none; }
      .page .modal.modal-active .call > .wrap {
        background-color: #211621;
        padding: 0.7vmin 0.36842vw;
        width: 100%;
        height: 100%;
        border-radius: 1vmin; }
        .page .modal.modal-active .call > .wrap > .form {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          background-color: #FFCA53;
          border-radius: 0.8vmin; }
          .page .modal.modal-active .call > .wrap > .form > .descr {
            color: #211621;
            text-align: center;
            font-weight: 500;
            font-size: 1.8vmin;
            line-height: 150%; }
          .page .modal.modal-active .call > .wrap > .form > .label {
            position: relative;
            width: 30vmin;
            height: 4.4vmin; }
            .page .modal.modal-active .call > .wrap > .form > .label > .js-validate-error-label {
              position: absolute;
              top: 0;
              left: 0;
              display: flex;
              align-items: center;
              width: 100%;
              height: 100%;
              padding-left: 1vw;
              font-family: "Intro";
              font-size: 1.6vmin;
              line-height: 2.6vmin;
              box-shadow: inset 0 0 0.8vmin -0.1vmin #D50000;
              border-radius: 0.5vmin; }
            .page .modal.modal-active .call > .wrap > .form > .label > .input {
              position: relative;
              width: 100%;
              height: 100%;
              font-family: "Intro";
              font-weight: 300;
              font-size: 1.6vmin;
              color: #211621;
              padding-left: 1.05263vw;
              border: none;
              border-radius: 0.5vmin;
              background-color: #ffffff;
              box-shadow: 0.6vmin 0.6vmin 1.5vmin -0.1vmin rgba(0, 0, 0, 0.25);
              transition: background 0.25s ease; }
              .page .modal.modal-active .call > .wrap > .form > .label > .input:hover {
                background: #FFE8CD;
                transition: background 0.25s ease; }
              .page .modal.modal-active .call > .wrap > .form > .label > .input:focus {
                background: #ffffff;
                border: 0.01vmin solid #1576D2; }
                .page .modal.modal-active .call > .wrap > .form > .label > .input:focus::placeholder {
                  font-size: 0; }
            .page .modal.modal-active .call > .wrap > .form > .label > .js-validate-error-field::placeholder {
              font-size: 0; }
          .page .modal.modal-active .call > .wrap > .form > .btn {
            width: 30vmin;
            height: 4.4vmin;
            font-family: "Roboto";
            font-weight: 600;
            font-size: 1.6vmin;
            padding: 0.5vmin 0.78947vw;
            color: #ffffff;
            box-shadow: 0.6vmin 0.6vmin 1.5vmin -0.1vmin rgba(0, 0, 0, 0.25);
            border: none;
            border-radius: 0.4vmin;
            background: #787DFF;
            transition: transform 0.3s ease; }
            .page .modal.modal-active .call > .wrap > .form > .btn:hover {
              transform: perspective(40vmin) rotateX(30deg);
              background: #878CFF;
              transition: all 0.3s ease; }
            .page .modal.modal-active .call > .wrap > .form > .btn:active {
              background: #6E73FF; }
    .page .modal.modal-active .congratulate {
      width: 50vmin;
      height: 30vmin;
      position: relative;
      display: none;
      flex-direction: column;
      padding: 0.7vmin 0.36842vw;
      background-color: #FFCA53;
      border-radius: 0.8vmin;
      z-index: 15;
      box-shadow: 0.6vmin 0.6vmin 1.5vmin -0.1vmin rgba(0, 0, 0, 0.25);
      transition: display 0.3s ease; }
      .page .modal.modal-active .congratulate.active {
        display: flex;
        transition: display 0.3s ease; }
      .page .modal.modal-active .congratulate > .wrap {
        background-color: #211621;
        padding: 0.7vmin 0.36842vw;
        width: 100%;
        height: 100%;
        border-radius: 1vmin; }
        .page .modal.modal-active .congratulate > .wrap > .main-block {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          background-color: #FFCA53;
          border-radius: 0.8vmin;
          padding: 1.5vmin 0 0; }
          .page .modal.modal-active .congratulate > .wrap > .main-block > .descr {
            color: #211621;
            text-align: center;
            font-weight: 500;
            font-size: 2vmin;
            line-height: 150%;
            text-align: center; }
          .page .modal.modal-active .congratulate > .wrap > .main-block > .btn {
            width: 20vmin;
            height: 4.4vmin;
            font-family: "Roboto";
            font-weight: 600;
            font-size: 1.6vmin;
            padding: 0.5vmin 0.78947vw;
            color: #ffffff;
            box-shadow: 0.6vmin 0.6vmin 1.5vmin -0.1vmin rgba(0, 0, 0, 0.25);
            border: none;
            border-radius: 0.4vmin;
            background: #787DFF;
            transition: transform 0.3s ease; }
            .page .modal.modal-active .congratulate > .wrap > .main-block > .btn:hover {
              transform: perspective(40vmin) rotateX(30deg);
              background: #878CFF;
              transition: all 0.3s ease; }
            .page .modal.modal-active .congratulate > .wrap > .main-block > .btn:active {
              background: #6E73FF; }
