.page
{
    &.main-active
    {
        overflow: hidden;
    }

    .header
    {
        width: 100%;
        height: auto;

        padding: v(50.5) 0 0;

        display: flex;
        justify-content: center;

        > .container
        {
            width: v(1900);
    
            padding: 0 w(65.5) 0 w(65.1);
            display: flex;
            justify-content: center;

            > .contact-list
            {
                width: 100%;

                display: flex;
                justify-content: center;

                > .item
                {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    margin: 0 w(20);

                    &:nth-child(1)
                    {
                        background-image: url("../img/mail.svg");
                        background-repeat: no-repeat;
                        background-size: v(18) v(14);
                        background-position: left;
            
                        padding-left: w(20);
            
                        transition: background-image 0.25s ease;
                        
                        &:hover
                        {
                            background-image: url("../img/mail-blue.svg");
                            transition: background-image 0.25s ease;
                        }
                    }
            
                    &:nth-child(2)
                    {
                        background-image: url("../img/phone.svg");
                        background-repeat: no-repeat;
                        background-size: v(12) v(17);
                        background-position: left;
            
                        padding-left: w(15);
            
                        transition: background-image 0.25s ease;
            
                        &:hover
                        {
                            background-image: url("../img/phone-blue.svg");
                            transition: background-image 0.25s ease;
                        }
                    }
            
                    &:nth-child(3)
                    {
                        background-image: url("../img/call.svg");
                        background-repeat: no-repeat;
                        background-size: v(18) v(14);
                        background-position: left;
            
                        padding-left: w(25);
            
                        transition: background-image 0.25s ease;
            
                        &:hover
                        {
                            background-image: url("../img/call-blue.svg");
                            transition: background-image 0.25s ease;
                        }
                    }

                    > .link
                    {
                        color: $maintext;
                
                        font-weight: 300;
                        font-size: v(16);
                        line-height: v(24);
                
                        display: inline-block;
                
                        border-bottom: v(1) solid #CBCBCB;
                
                        transition: color 0.25s ease;
                
                        &:hover
                        {
                            color: #6E73FF;
                            border-bottom: v(1) solid #6E73FF;
                
                            transition: all 0.25s ease;
                        }
                    }

                    > .call-btn
                    {
                        color: $maintext;

                        border: none;
                        border-bottom: v(1) solid #CBCBCB;
                        background-color: transparent;

                        font-weight: 300;
                        font-size: v(16);
                        line-height: v(24);

                        transition: color 0.25s ease;

                        &:hover
                        {
                            color: #6E73FF;
                            border-bottom: v(1) solid #6E73FF;

                            transition: all 0.25s ease;
                        }
                    }
                }
            }
        }
    }

    .main
    {
        overflow: hidden;

        > .home
        {
            width: 100%;
            height: auto;

            margin-top: v(215);
            margin-bottom: v(239);
            
            display: flex;
            flex-direction: column;

            > .container
            {
                display: flex;
                flex-direction: column;

                > .title
                {
                    font-size: v(24);
                    line-height: v(36);

                    text-align: center;
                }

                > .logo
                {
                    width: v(453.7);
                    height: v(190.63);

                    margin: v(28) auto v(60.37);
                }

                > .call-btn
                {
                    background: #787DFF;
                    border-radius: v(4);
                    border: none;

                    width: v(241);
                    height: v(44);

                    padding: v(10) v(50);
                    margin: 0 auto;

                    color: #ffffff;

                    font-family: "Roboto";
                    font-weight: 600;
                    font-size: v(16);

                    transition: transform 0.3s ease;

                    &:hover
                    {
                        transform: perspective(v(400)) rotateX(30deg);
                        background: #878CFF;

                        transition: all 0.3s ease;
                    }

                    &:active
                    {
                        background: #6E73FF;
                    }
                }
            }
        }

        > .event
        {
            position: relative;

            width: 100%;
            height: auto;

            background-color: rgba($color: #000000, $alpha: 0.6);

            padding: v(90) 0;
            margin-bottom: v(165);

            display: flex;
            justify-content: center;

            > .container
            {
                height: 100%;

                display: flex;
                flex-direction: column;
                align-items: center;

                > .title
                {
                    color: #ffffff;

                    font-weight: 700;
                    font-size: v(48);
                    line-height: v(48);

                    margin-bottom: v(32);
                }

                > .event-list
                {
                    display: flex;
                    flex-direction: column;

                    > .item
                    {
                        margin-top: v(23);
                        padding: 0 v(50);
                        text-align: center;

                        transition: background 0.3s ease;

                        cursor: pointer;

                        &:hover
                        {
                            background: url("../img/Line1.svg") no-repeat,
                            url("../img/Line2.svg") no-repeat;
                            background-size: v(38) v(1);
                            background-position: left 35%, right 35%;
            
                            transition: background 0.3s ease;

                            > .image
                            {
                                opacity: 1;
                                transition: opacity 0.3s ease;
                            }
                        }

                        > .descr
                        {
                            color: #ffffff;

                            font-weight: 400;
                            font-size: v(24);

                            line-height: 150%;
                        }

                        > .image
                        {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;

                            width: 100%;
                            height: 100%;

                            object-fit: cover;

                            opacity: 0;
                            z-index: -1;

                            transition: opacity 0.3s ease;

                            &_1
                            {
                                opacity: 1;
                                transition: opacity 0.3s ease;
                            }
                        }
                    }
                }
            }
        }

        > .projects
        {
            width: 100%;
            height: auto;

            padding-bottom: v(142);
            margin-bottom: v(52);

            border-bottom: v(1) solid #E3E1EF;

            display: flex;
            justify-content: center;

            > .container
            {
                width: v(1200);
                height: auto;

                display: flex;
                flex-direction: column;
                align-items: center;

                padding: 0 6vw;

                > .title
                {
                    font-weight: 700;
                    font-size: v(48);
                    line-height: v(48);

                    text-align: center;

                    background-image: url("../img/Rectangle-project.svg");
                    background-repeat: no-repeat;
                    background-size: v(207) v(56);
                    background-position: left center;
                }

                > .projects-list
                {
                    width: v(1200);
                    height: auto;

                    max-width: 100%;

                    display: flex;

                    margin-top: v(57);

                    > div > div > div   // Карусель
                    {
                        > .item
                        {
                            width: v(346);

                            margin: 0 v(8);

                            > .link
                            {
                                text-decoration-style: none;

                                > article
                                {
                                    width: 100%;
                                    height: auto;

                                    display: flex;
                                    flex-direction: column-reverse;

                                    &:hover .descr
                                    {
                                        color: #6E73FF;
                                        border-bottom: v(1) solid #6E73FF;
                                        transition: all 0.25s ease;
                                    }

                                    > .descr
                                    {
                                        color: $maintext;

                                        margin: v(20) auto 0;

                                        font-size: v(24);
                                        line-height: 150%;
                                        text-align: center;

                                        border-bottom: v(1) solid #CBCBCB;
                                        transition: color 0.25s ease;
                                    }

                                    > .image
                                    {
                                        width: 100%;
                                        height: v(237);

                                        object-fit: cover;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        > .team
        {
            width: 100%;
            height: auto;

            margin-bottom: v(120);

            display: flex;
            justify-content: center;

            > .container
            {
                width: v(1200);
                height: auto;
                
                display: flex;
                flex-direction: column;
                align-items: center;

                > .title
                {
                    font-weight: 700;
                    font-size: v(48);
                    line-height: v(48);
                    text-align: center;

                    background-image: url("../img/rectangle-team.svg");
                    background-repeat: no-repeat;
                    background-size: v(159) v(56);
                    background-position: left center;
                }

                > .subtitle
                {
                    width: v(512);

                    margin: v(20) 0 v(84);

                    font-weight: 300;
                    font-size: v(16);
                    line-height: 150%;
                    text-align: center;
                }

                > .main-block
                {
                    width: 100%;
                    height: auto;

                    display: flex;
                    justify-content: space-between;

                    > .team-list
                    {
                        // width: v(600);
                        width: 50%;
                        height: auto;

                        display: flex;
                        flex-direction: column;

                        &_right {
                            padding-top: v(216);
                        }

                        > .item
                        {
                            width: 100%;
                            height: v(379);

                            margin-bottom: v(60);

                            > article
                            {
                                position: relative;

                                width: 100%;
                                height: 100%;

                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;

                                &:hover
                                {
                                    .name
                                    {
                                        background-color: #FFCA53;
                                        transition: all 0.3s ease;
                                    }
                        
                                    .image
                                    {
                                        transform: rotate(-2deg);
                                        transition: all 0.3s ease;
                        
                                        &_right
                                        {
                                            transform: rotate(2deg);
                                        }
                                    }
                                }

                                > .name
                                {
                                    width: 100%;

                                    font-weight: 300;
                                    font-size: v(48);
                                    line-height: v(48);
                                    text-align: right;

                                    // padding: v(12) w(136) v(12) w(348);
                                    padding: v(12) 3vw v(12) 3vw;
                                    margin-bottom: v(16);

                                    border: v(1) solid #FFCA53;

                                    transition: background-color 0.3s ease;

                                    &_right
                                    {
                                        // padding: v(12) w(348) v(12) w(114);
                                        padding: v(12) 3vw v(12) 3vw;
                                        text-align: left;
                                    }
                                }

                                > .descr
                                {
                                    font-weight: 300;
                                    font-size: v(16);
                                    line-height: 150%;

                                    // margin: 0 auto 0 v(350);
                                    width: v(180);

                                    &_right
                                    {
                                        width: v(200);
                                        margin: 0 v(350) 0 auto;
                                    }
                                }

                                > .image
                                {
                                    position: absolute;
                                    top: v(16);
                                    left: v(16);

                                    width: v(300);
                                    height: v(365);

                                    object-fit: cover;

                                    z-index: 3;

                                    transition: transform 0.3s ease;

                                    &_right
                                    {
                                        left: auto;
                                        right: v(16);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        > .contact
        {
            width: 100%;
            height: auto;

            padding-bottom: v(107);
            margin-bottom: v(30);
            border-bottom: v(1) solid #E3E1EF;

            display: flex;
            justify-content: center;

            > .container
            {
                width: v(540);
                height: auto;

                display: flex;
                flex-direction: column;
                align-items: center;

                > .title
                {
                    font-weight: 700;
                    font-size: v(48);
                    line-height: v(48);
                    text-align: center;

                    background-image: url("../img/Rectangle-contact.svg");
                    background-repeat: no-repeat;
                    background-size: v(319) v(56);
                    background-position: left center;
                }

                > .subtitle
                {
                    font-weight: 300;
                    font-size: v(16);
                    line-height: 150%;

                    margin: v(20) 0 v(29);
                }

                > .block-btn
                {
                    width: 100%;
        
                    display: flex;
                    justify-content: space-between;

                    > .btn
                    {
                        width: v(241);
                        height: v(44);

                        padding: v(10) v(50);

                        background: #787DFF;
                        border: none;
                        border-radius: v(4);

                        font-family: "Roboto";
                        font-weight: 600;
                        font-size: v(16);
                        line-height: 150%;
                        text-align: center;

                        color: #ffffff;

                        transition: transform 0.3s ease;

                        &:hover
                        {
                            transform: perspective(v(400)) rotateX(30deg);
                            background: #878CFF;

                            transition: all 0.3s ease;
                        }

                        &:active
                        {
                            background: #6E73FF;
                        }
                    }

                    > .link
                    {
                        width: v(241);
                        height: v(44);

                        padding: v(10) v(50);

                        background: #787DFF;
                        border: none;
                        border-radius: v(4);

                        font-family: "Roboto";
                        font-weight: 600;
                        font-size: v(16);
                        line-height: 150%;
                        text-align: center;

                        color: #ffffff;

                        transition: transform 0.3s ease;

                        &:hover
                        {
                            transform: perspective(v(400)) rotateX(30deg);
                            background: #878CFF;

                            transition: all 0.3s ease;
                        }

                        &:active
                        {
                            background: #6E73FF;
                        }
                    }
                }
            }
        }
    }

    .footer
    {
        width: 100%;
        height: auto;

        display: flex;
        justify-content: center;

        padding-bottom: v(30);

        > .container
        {
            width: v(600);

            display: flex;
            justify-content: space-between;

            > .left
            {
                display: flex;
                flex-direction: column;

                > .top-left
                {
                    display: flex;

                    > .logo
                    {
                        width: v(97);
                        height: v(40.76);

                        margin-right: w(12);
                    }

                    > .logo-descr
                    {
                        font-weight: 700;
                        font-size: v(24);
                        line-height: 150%;
                    }
                }

                > .address
                {
                    margin-top: v(22);

                    > .address-list
                    {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        > .item
                        {
                            &:nth-child(1)
                            {
                                background-image: url("../img/phone.svg");
                                background-repeat: no-repeat;
                                background-size: v(12) v(17);
                                background-position: left 75%;
                    
                                padding-left: w(16);
                    
                                transition: background-image 0.25s ease;
                                
                                &:hover
                                {
                                    background-image: url("../img/phone-blue.svg");
                                    transition: background-image 0.25s ease;
                                }
                            }
                    
                            &:nth-child(2)
                            {
                                background-image: url("../img/call.svg");
                                background-repeat: no-repeat;
                                background-size: v(18) v(14);
                                background-position: left 75%;
                                
                                padding-left: w(20);
                    
                                transition: background-image 0.25s ease;
                                
                                &:hover
                                {
                                    background-image: url("../img/call-blue.svg");
                                    transition: background-image 0.25s ease;
                                }
                            }
                    
                            &:nth-child(3)
                            {
                                background-image: url("../img/mail.svg");
                                background-repeat: no-repeat;
                                background-size: v(18) v(14);
                                background-position: left 75%;
                    
                                padding-left: w(20);
                    
                                transition: background-image 0.25s ease;
                                
                                &:hover
                                {
                                    background-image: url("../img/mail-blue.svg");
                                    transition: background-image 0.25s ease;
                                }
                            }

                            > .link
                            {
                                font-weight: 300;
                                font-size: v(16);
                                line-height: 150%;

                                color: $maintext;

                                display: inline-block;

                                margin: v(12) auto 0;

                                border-bottom: v(1) solid #CBCBCB;

                                transition: color 0.25s ease;

                                &:hover
                                {
                                    color: #6E73FF;
                                    border-bottom: v(1) solid #6E73FF;

                                    transition: all 0.25s ease;
                                }
                            }

                            > .btn-call
                            {
                                font-weight: 300;
                                font-size: v(16);
                                line-height: 150%;
                                padding: 0;

                                color: $maintext;

                                border: none;
                                background-color: transparent;

                                margin: v(12) auto 0;

                                border-bottom: v(1) solid #CBCBCB;

                                transition: color 0.25s ease;

                                &:hover
                                {
                                    color: #6E73FF;
                                    border-bottom: v(1) solid #6E73FF;

                                    transition: all 0.25s ease;
                                }
                            }
                        }
                    }
                }
            }

            > .right
            {
                display: flex;
                flex-direction: column;

                > .descr
                {
                    font-weight: 300;
                    font-size: v(16);
                    line-height: 150%;

                    margin-bottom: v(2);
                }

                > .messanger-list
                {
                    display: flex;
                    align-items: center;

                    margin-bottom: v(21);

                    > .item
                    {
                       
                        > .link
                        {
                            display: inline-block;

                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: center;

                            &_whatsapp
                            {
                                width: v(33);
                                height: v(34);
                    
                                background-image: url("../img/watsap.svg");
                            }
                    
                            &_telegram
                            {
                                width: v(24);
                                height: v(24);
                    
                                background-image: url("../img/telegram.svg");

                                margin: 0 v(16) 0 v(14);
                            }
                    
                            &_viber
                            {
                                width: v(22);
                                height: v(24);
                    
                                background-image: url("../img/viber.svg");
                            }
                        }
                    }
                }

                > .social-list
                {
                    display: flex;
                    align-items: center;

                    margin-bottom: v(24);

                    > .item
                    {
                        > .link
                        {
                            display: inline-block;

                            width: v(24);
                            height: v(24);

                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: center;

                            &_instagram
                            {
                                background-image: url("../img/instagram.svg");
                    
                                margin-right: v(15);
                            }
                    
                            &_facebook
                            {
                                background-image: url("../img/fb.svg");
                            }
                        }
                    }
                }

                > .rights
                {
                    font-weight: 300;
                    font-size: v(16);
                    line-height: 150%;
                }
            }
        }
    }

    .modal
    {
        position: fixed;
        top: -100%;
        left: 0;

        z-index: 3;

        width: 100%;
        height: 100vh;

        background-color: rgba($color: #000000, $alpha: 0.5);

        display: flex;
        justify-content: center;
        align-items: center;

        transition: top 0.3s ease;

        &.modal-active
        {
            top: 0;
            transition: top 0.3s ease;

            .click-area
            {
                width: 100%;
                height: 100%;

                position: absolute;
                top: 0;
                left: 0;

                z-index: 5;
            }

            .call
            {
                width: v(600);
                max-width: 100%;
                height: v(400);

                position: relative;

                display: flex;
                flex-direction: column;

                padding: v(7) w(7);

                background-color: #FFCA53;
                border-radius: v(8);

                z-index: 15;

                box-shadow: v(6) v(6) v(15) v(-1) rgba(0, 0, 0, 0.25);

                transition: top 0.25s ease;

                &.hide 
                {
                    display: none;
                }

                > .wrap
                {
                    background-color: $maintext;
                    padding: v(7) w(7);

                    width: 100%;
                    height: 100%;
                    border-radius: v(10);

                    > .form
                    {
                        width: 100%;
                        height: 100%;

                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-evenly;

                        background-color: #FFCA53;
                        border-radius: v(8);

                        > .descr
                        {
                            color: $maintext;
                            text-align: center;

                            font-weight: 500;
                            font-size: v(18);
                            line-height: 150%;
                        }

                        > .label
                        {
                            position: relative;
                            width: v(300);
                            height: v(44);

                            > .js-validate-error-label
                            {
                                position: absolute;
                                top: 0;
                                left: 0;
                        
                                display: flex;
                                align-items: center;
                        
                                width: 100%;
                                height: 100%;

                                padding-left: 1vw;

                                font-family: "Intro";
                                font-size: v(16);

                                line-height: v(26);

                                box-shadow: inset 0 0 v(8) v(-1) #D50000;
                                border-radius: v(5);
                            }

                            > .input
                            {
                                position: relative;
                                width: 100%;
                                height: 100%;

                                font-family: "Intro";
                                font-weight: 300;
                                font-size: v(16);

                                color: $maintext;

                                padding-left: w(20);

                                border: none;
                                border-radius: v(5);

                                background-color: #ffffff;

                                box-shadow: v(6) v(6) v(15) v(-1) rgba(0, 0, 0, 0.25);

                                transition: background 0.25s ease;

                                &:hover
                                {
                                    background: #FFE8CD;
                                    transition: background 0.25s ease;
                                }

                                &:focus
                                {
                                    background: #ffffff;
                                    border: v(0.1) solid #1576D2;

                                    &::placeholder
                                    {
                                        font-size: 0;
                                    }
                                }
                            }

                            > .js-validate-error-field::placeholder
                            {
                                font-size: 0;
                            }
                        }

                        > .btn
                        {
                            width: v(300);
                            height: v(44);

                            font-family: "Roboto";
                            font-weight: 600;
                            font-size: v(16);

                            padding: v(5) w(15);

                            color: #ffffff;

                            box-shadow: v(6) v(6) v(15) v(-1) rgba(0, 0, 0, 0.25);

                            border: none;
                            border-radius: v(4);
                            background: #787DFF;

                            transition: transform 0.3s ease;

                            &:hover
                            {
                                transform: perspective(v(400)) rotateX(30deg);
                                background: #878CFF;

                                transition: all 0.3s ease;
                            }

                            &:active
                            {
                                background: #6E73FF;
                            }
                        }
                    }
                }
            }

            .congratulate
            {
                width: v(500);
                height: v(300);

                position: relative;

                display: none;
                flex-direction: column;

                padding: v(7) w(7);

                background-color: #FFCA53;
                border-radius: v(8);

                z-index: 15;

                box-shadow: v(6) v(6) v(15) v(-1) rgba(0, 0, 0, 0.25);

                transition: display 0.3s ease;

                &.active
                {
                    display: flex;
                    transition: display 0.3s ease;
                }

                > .wrap
                {
                    background-color: $maintext;
                    padding: v(7) w(7);

                    width: 100%;
                    height: 100%;
                    border-radius: v(10);

                    > .main-block
                    {
                        width: 100%;
                        height: 100%;

                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-evenly;

                        background-color: #FFCA53;
                        border-radius: v(8);

                        padding: v(15) 0 0;

                        > .descr
                        {
                            color: $maintext;
                            text-align: center;

                            font-weight: 500;
                            font-size: v(20);
                            line-height: 150%;
                            text-align: center;
                        }

                        > .btn
                        {
                            width: v(200);
                            height: v(44);

                            font-family: "Roboto";
                            font-weight: 600;
                            font-size: v(16);

                            padding: v(5) w(15);

                            color: #ffffff;

                            box-shadow: v(6) v(6) v(15) v(-1) rgba(0, 0, 0, 0.25);

                            border: none;
                            border-radius: v(4);
                            background: #787DFF;

                            transition: transform 0.3s ease;

                            &:hover
                            {
                                transform: perspective(v(400)) rotateX(30deg);
                                background: #878CFF;

                                transition: all 0.3s ease;
                            }

                            &:active
                            {
                                background: #6E73FF;
                            }
                        }
                    }
                }
            }
        }
    }
}