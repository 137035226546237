@font-face {
    font-weight: 300;
    font-style: normal;
    font-family: "Intro";
    font-display: swap;

    src: local("Intro Book"),
         url("../fonts/Intro-Book.woff") format("woff");
}

@font-face {
    font-weight: 400;
    font-style: normal;
    font-family: "Intro";
    font-display: swap;
  
    src: local("Intro Regular"),
         url("../fonts/Intro-Regular.woff") format("woff");
  }

@font-face {
    font-weight: 700;
    font-style: normal;
    font-family: "Intro";
    font-display: swap;

    src: local("Intro Bold"),
         url("../fonts/Intro-Bold.woff") format("woff");
}

@font-face {
    font-weight: 500;
    font-style: normal;
    font-family: "Roboto";
    font-display: swap;

    src: local("Roboto Regular"),
         url("../fonts/Roboto-Regular.woff") format("woff");
}